import type { AsyncData } from '#app'
import type { Register } from '~/entities/register'
import { BaseResource } from '~/resources/base-resource'
import { ApiGetResponse } from '~/util/api-get-response'

export class RegisterResource extends BaseResource<Register> {
  constructor() {
    super('register', 'Register')
  }

  getWithInvitation(
    slug: string,
    invitation: string
  ): AsyncData<ApiGetResponse<Register> | null, unknown> {
    return useFetch(`${this.getApiUrl()}${slug}/with-invitation/${invitation}`, {
      ...this.getRequestConfig(),
      transform: (response: { item: Register; class: string }): ApiGetResponse<Register> => {
        return new ApiGetResponse<Register>(response.item, response.class)
      },
      default: () => null
    })
  }

  getForSharing(slug: string): AsyncData<ApiGetResponse<Register> | null, unknown> {
    return useFetch(`${this.getApiUrl()}${slug}/share`, {
      ...this.getRequestConfig(),
      transform: (response: { item: Register; class: string }): ApiGetResponse<Register> => {
        return new ApiGetResponse<Register>(response.item, response.class)
      },
      default: () => null
    })
  }
}
